import { createRouter, createWebHashHistory } from 'vue-router';
import { Role } from './helpers';
import store from '@/store';

const routes = [
    // {
    //     path: '/view/:page',
    //     name: 'Page',
    //     exact: true,
    //     props: {
    //         default: true,
    //         'appContent': false,
    //         'page': "JAWOHL",
    //         'componentKey': "dashboardPanelValues"
    //     },
    //     component: () => import('./views/Page.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Klimacomputer' }],
    //         authorize: [Role.Admin, Role.Master, Role.User],
    //     },
    // },
    {
        path: '/',
        name: 'Entry',
        props: {
            'appContent': false,
            'page': "dashboard",
            'componentKey': "dashboardPanelValues"
        },
        component: () => import('./views/Page.vue'),
        meta: {
            breadcrumb: [{ label: 'Bedienung' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        props: {
            'appContent': false,
            'page': "dashboard",
            'componentKey': "dashboardPanelValues"
        },
        component: () => import('./views/Page.vue'),
        meta: {
            breadcrumb: [{ label: 'Bedienung' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/view/:page',
        name: 'Page',
        props: {
            'appContent': false,
            'page': "JAWOHL",
            'componentKey': "dashboardPanelValues"
        },
        component: () => import('./views/Page.vue'),
        meta: {
            breadcrumb: [{ label: 'Bedienung' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/vents/:page',
        name: 'vents',
        props: {
            'appContent': false,
            'page': "JAWOHL",
            'componentKey': "dashboardPanelValues"
        },
        component: () => import('./views/VentsTable.vue'),
        meta: {
            breadcrumb: [{ label: 'Lüftungsparameter' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/weather',
        name: 'weather',
        exact: true,
        component: () => import('./views/weather.vue'),
        meta: {
            breadcrumb: [{ label: 'Wetterstation' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/alarms',
        name: 'alarmList',
        exact: true,
        component: () => import('./views/alarmList.vue'),
        meta: {
            breadcrumb: [{ label: 'Alarmliste' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/ioconfig',
        // name: 'IOTable',
        name: 'ioConfig',
        exact: true,
        // component: () => import('./views/IOTable.vue'),
        component: () => import('./views/ioConfig.vue'),
        meta: {
            breadcrumb: [{ label: 'IO-Konfiguration' }],
            authorize: [Role.Admin],
        },
        // beforeEnter: (to, from, next) => {
        //     if(store.getters.getUser.group[0].group === 'Administrator') {
        //         next(); // Take you to /something
        //     } else {
        //         // If params.blah is blank or in your case, does not have permission, redirect back to the home page
        //         next('/weather'); 
        //     }
        // }
    },
    {
        path: '/users',
        name: 'UserTable',
        exact: true,
        component: () => import('./views/UserTable.vue'),
        meta: {
            breadcrumb: [{ label: 'Benutzer' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/userprofile',
        name: 'UserProfile',
        exact: true,
        component: () => import('./views/userProfile.vue'),
        meta: {
            breadcrumb: [{ label: 'Benutzerprofil' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/typesconfig',
        name: 'typesTable',
        exact: true,
        component: () => import('./views/TypesTable.vue'),
        meta: {
            breadcrumb: [{ label: 'Typen-Konfiguration' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/table3',
        name: 'Table3',
        exact: true,
        component: () => import('./views/Table3.vue'),
        meta: {
            breadcrumb: [{ label: 'Parametereinstellungen' }],
        },
    },
    {
        path: '/alarmlog',
        name: 'alarmLog',
        exact: true,
        component: () => import('./views/alarmLog.vue'),
        meta: {
            breadcrumb: [{ label: 'Alarm-Log' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/changelog',
        name: 'changeLog',
        exact: true,
        component: () => import('./views/changeLog.vue'),
        meta: {
            breadcrumb: [{ label: 'Änderungs-Log' }],
            authorize: [Role.Admin, Role.Master, Role.User],
        },
    },
    {
        path: '/systemlog',
        name: 'systemLog',
        exact: true,
        component: () => import('./views/systemLog.vue'),
        meta: {
            breadcrumb: [{ label: 'System-Log' }],
            authorize: [Role.Admin, Role.Master],
        },
    },
    {
        path: '/terminal',
        name: 'theTerminal',
        exact: true,
        component: () => import('./views/Terminal.vue'),
        meta: {
            breadcrumb: [{ label: 'Terminal' }],
            authorize: [Role.Admin, Role.Master],
        },
    },
    {
        path: '/systemconfig',
        name: 'configSys',
        exact: true,
        component: () => import('./views/ConfigSys.vue'),
        meta: {
            breadcrumb: [{ parent: 'Systemeinstellungen', label: 'System' }],
            authorize: [Role.Admin, Role.Master],
        },
    },
    {
        path: '/networkconfig',
        name: 'configSysNetwork',
        exact: true,
        component: () => import('./views/ConfigSysNetwork.vue'),
        meta: {
            breadcrumb: [{ parent: 'Systemeinstellungen', label: 'Netzwerk' }],
            authorize: [Role.Admin, Role.Master],
        },
    },
    {
        path: '/notificationconfig',
        name: 'configSysNotifications',
        exact: true,
        component: () => import('./views/ConfigSysNotifications.vue'),
        meta: {
            breadcrumb: [{ parent: 'Systemeinstellungen', label: 'Benachrichtigungen' }],
            authorize: [Role.Admin, Role.Master],
        },
    },
    {
        path: '/menuconfig',
        name: 'menuTable',
        exact: true,
        component: () => import('./views/MenuTable.vue'),
        meta: {
            breadcrumb: [{ parent: 'Seiten und Menüs', label: 'Menü' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/pageconfig',
        name: 'pageTable',
        exact: true,
        component: () => import('./views/PageTable.vue'),
        meta: {
            breadcrumb: [{ parent: 'Seiten und Menüs', label: 'Seiten' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/opcConfig',
        name: 'opcConfig',
        exact: true,
        component: () => import('./views/opcConfig.vue'),
        meta: {
            breadcrumb: [{ parent: 'PLC-Konfiguration', label: 'OPC-UA' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/moduleConfig',
        name: 'moduleConfig',
        exact: true,
        component: () => import('./views/moduleConfig.vue'),
        meta: {
            breadcrumb: [{ parent: 'PLC-Konfiguration', label: 'Module' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/moduleScheme',
        name: 'moduleScheme',
        exact: true,
        component: () => import('./views/moduleScheme.vue'),
        meta: {
            breadcrumb: [{ parent: 'PLC-Konfiguration', label: 'Module Scheme' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/parameterConfig',
        name: 'parameterConfig',
        exact: true,
        component: () => import('./views/parameterConfig.vue'),
        meta: {
            breadcrumb: [{ parent: 'PLC-Konfiguration', label: 'Parameter' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/moduleBindings',
        name: 'moduleBindings',
        exact: true,
        component: () => import('./views/moduleBindings.vue'),
        meta: {
            breadcrumb: [{ parent: 'PLC-Konfiguration', label: 'Module Bindings' }],
            authorize: [Role.Admin],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        meta: {
            guest: true
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Login.vue'),
        meta: {
            guest: false
        }
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/Landing.vue')
    },
    {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
        redirect: '/notfound',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    // const currentUser = authenticationService.currentUserValue;
    // const currentUser = store.getters.getUser;
    const currentUser = store.getters['auth/getUser'];
    const loginStatus = store.getters['auth/loginStatus'];

    console.log(`[AUTH] <TOKEN> ${localStorage.getItem('token')}`);
    console.log(`[AUTH] <USER> ${localStorage.getItem('user')}`);
    console.log(`[AUTH] <LOGINSTATUS> ${loginStatus}`);
    console.log(authorize);
    console.log(currentUser);

    // console.log(currentUser);

    if (authorize) {
        if (loginStatus !== 'success') {
            // not logged in so redirect to login page with the return url
            return next({ path: '/login', query: { returnUrl: to.path } });
        }

        // check if route is restricted by role
        console.log(authorize.length, currentUser.role);
        if (authorize.length && !authorize.includes(currentUser.role)) {
            // role not authorised so redirect to home page
            return next({ path: '/access' });
        }
    }

    // redirect, if already logged in
    if (loginStatus === 'success') {
        if (to.path === "/") {
            return next({ path: '/view/dashboard' });
        }
        if (to.path === "/login") {
            return next({ path: '/view/dashboard' });
        }
        if (to.path === "/logout") {
            store.dispatch('auth/logout');
            return next({ path: '/' });
        }
        if (to.path === "/weather") {
            return next({ path: '/view/weather' });
        }
        if (to.path === "/dashboard") {
            return next({ path: '/view/dashboard' });
        }
    }

    next();
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next();
//             return
//         }
//         next('/login');
//     } else {
//         next();
//     }
// });

// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.guest)) {
//         if (store.getters.isLoggedIn) {
//             next("/weather");
//             return;
//         }
//         next();
//     } else {
//         next();
//     }
// });

export default router;